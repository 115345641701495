import React, { useState, useEffect } from "react"
import { collection, query, orderBy, onSnapshot } from "firebase/firestore"
import { db } from "./firebase"
import Task from "./Task"
import Title from "../Headings/Title"

function TaskManager() {
  const [tasks, setTasks] = useState([])

  /* function to get all tasks from firestore in realtime */
  useEffect(() => {
    const taskColRef = query(
      collection(db, "tasks"),
      orderBy("created", "desc")
    )

    onSnapshot(taskColRef, snapshot => {
      setTasks(
        snapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
        }))
      )
    })
  }, [])

  return (
    <section className="task-section">
      <Title>Tasks...</Title>
      <div className="container py-5">
        {tasks.map(task => {
          return (
            <div>
              {task.data.completed && (
                <Task
                  id={task.id}
                  key={task.id}
                  title={task.data.title}
                  description={task.data.description}
                />
              )}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default TaskManager
