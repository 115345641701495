import React, { useState } from "react"
import ViewTask from "./ViewTask"
import { FaReadme } from "react-icons/fa"

function Task({ title, description }) {
  const [open, setOpen] = useState({ view: false, task: true })

  const handleClose = () => {
    setOpen({ view: false, task: true })
  }

  return (
    <div
      className="card shadow mb-4 border-primary"
      style={{
        backgroundColor: "#" + Math.random().toString(16).slice(-6) + "10",
      }}
    >
      {open.task && (
        <div className="card-body">
          <h4 className="fw-bold card-title">{title}</h4>
          <p className="card-text text-truncate">{description}</p>
          <div className="d-flex">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => setOpen({ ...open, view: true, task: false })}
            >
              <FaReadme /> View
            </button>
          </div>
        </div>
      )}

      {open.view && (
        <ViewTask
          onClose={handleClose}
          title={title}
          description={description}
          open={open.view}
        />
      )}
    </div>
  )
}

export default Task
