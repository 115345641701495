import React from "react"
import TaskManager from "../components/PublicCRUD/TaskManager"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"

const Tasks = () => (
  <Layout>
    <Seo title="Tasks" />
    <TaskManager />
  </Layout>
)

export default Tasks
